<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE COA LIST</title>
    <section class="content-header">
      <h1>
        Update COA List
        <br />
        <h4>Please Update COA List</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Accounting COA</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Data COA Lists</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">COA Code</label>
                  <input
                    type="text"
                    v-model="coakode"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">COA Name</label>
                  <input
                    type="hidden"
                    v-model="idxcoa"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="text"
                    v-model="coaname"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Kurs</label>
                  <input
                    type="text"
                    v-model="valkurs"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes COA</label>
                  <input
                    type="text"
                    v-model="notescoa"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Item Type</label>
                  <select2
                    :data="barangtipe"
                    :value="valuetype"
                    @update="updatetype($event)"
                  ></select2>
                  <!--<select
                    class="form-control"
                    v-model="tipebarang"
                    id="tipebarang"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="tipebrg in barangtipe"
                      :key="tipebrg.tipe"
                      :value="tipebrg.tipe"
                    >
                      {{ tipebrg.tipe }}
                    </option>
                  </select>-->
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier</label>
                  <select2
                    :data="suplist"
                    :value="valuesup"
                    @update="updatesup($event)"
                  ></select2>
                  <!--<select
                    class="form-control"
                    v-model="kodesupplier"
                    id="kdsup"
                  >
                    <option disabled>--Please Select--</option>
                    <option
                      v-for="listsupp in suplist"
                      :key="listsupp.kode_supplier"
                      :value="listsupp.kode_supplier"
                    >
                      {{ listsupp.nama_supplier }}
                    </option>
                  </select> -->
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Data COA Lists</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">POS</label>
                  <select class="form-control" v-model="poscoa" id="pos">
                    <option disabled>--Please select--</option>
                    <option
                      v-for="datapos in pos"
                      :key="datapos.id"
                      :value="datapos.value"
                    >
                      {{ datapos.value }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">SN</label>
                  <select class="form-control" v-model="sncoa" id="sn">
                    <option disabled>--Please select--</option>
                    <option
                      v-for="datasn in sn"
                      :key="datasn.id"
                      :value="datasn.value"
                    >
                      {{ datasn.value }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Initial Debit Balance</label>
                  <input
                    type="text"
                    v-model="awalsaldodeb"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Initial Credit Balance</label>
                  <input
                    type="text"
                    v-model="awalsaldokre"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/coa">
                  <button class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i> Back
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "updatecoa",
  data() {
    return {
      pos: [
        { value: "BALANCE", id: 1 },
        { value: "PROFIT AND LOSS", id: 2 }
      ],
      sn: [
        { value: "DEBIT", id: 1 },
        { value: "CREDIT", id: 2 }
      ],
      barangtipe: [],
      suplist: [],
      valuesup: "",
      valuetype: "",
      loading: false,
      classname: "",
      classid: "",
      subclassname: "",
      subclassid: "",
      coaname: "",
      coakode: "",
      notescoa: "",
      poscoa: "",
      tipebarang: "",
      kodesupplier: "",
      valkurs: "",
      sncoa: "",
      awalsaldodeb: "",
      awalsaldokre: "",
      idxcoa: ""
    };
  },
  created() {
    // this.loadData();
    this.getSupplierList()
    this.getbarangtipe()
    this.$route.params.id;
  },
  methods: {
    updatesup(value){
      this.valuesup = value;
    },
    updatetype(value){
      this.valuetype = value;
    },
    getSupplierList() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getalldatasupplier?length="+this.pageSize+"&page="+this.page;
      const urlAPIget =
        this.$apiurl + "supplier/getalldatasupplier?length=55000&page=1";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.suplist = resp.data.data;
          this.suplist.forEach((item) => {
            item.value = item.kode_supplier;
            item.label = item.nama_supplier;
          });
          this.loading = false;
          this.loadData()
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    },
    getbarangtipe() {
      this.loading = true;
      const urlAPIget =
        this.$apiurl + "kategori_barang/getkategori_barang_list_tipe";
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.barangtipe = resp.data.data;
          this.barangtipe.forEach((item) => {
            item.value = item.tipe;
            item.label = item.tipe;
          });
          this.loading = false;
          this.loadData()
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");

      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/coa/getcoabycoa_id?id="+this.$route.params.id;
      const urlAPIget =
        this.$apiurl + "coa/getcoabyid?id=" + this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp);
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, ID COA not found",
              showConfirmButton: false
            });
            this.loading = false;
            this.$router.push({ name: "coa" });
          } else {
            this.coaname = resp.data.data.coa_name;
            this.coakode = resp.data.data.coa_code;
            this.notescoa = resp.data.data.notes;
            this.poscoa = resp.data.data.pos;
            this.valkurs = resp.data.data.kurs;
            this.sncoa = resp.data.data.sn;
            this.awalsaldodeb = resp.data.data.saldo_awal_d;
            this.awalsaldokre = resp.data.data.saldo_awal_k;
            this.idxcoa = resp.data.data.id;
            this.valuetype = resp.data.data.tipe_barang;
            this.valuesup = resp.data.data.kode_supplier;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var namecoa = this.coaname;
      var coakode = this.coakode;
      var idx = this.idxcoa;
      var snc = this.sncoa;
      var posc = this.poscoa;

      var sup = this.valuesup
      var tip = this.valuetype
      var kur = this.valkurs

      if (namecoa == "" || coakode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Name / ID COA can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (snc == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "SN COA can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (posc == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "POS COA can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          coa_code: this.coakode,
          coa_name: this.coaname,
          notes: this.notescoa,
          pos: this.poscoa,
          sn: this.sncoa,
          saldo_awal_d: this.awalsaldodeb,
          saldo_awal_k: this.awalsaldokre,
          kode_user: kodeuser,
          kode_supplier: sup,
          tipe_barang: tip,
          kurs: kur,
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/coa/updatecoa/"+ idx;
        const urlAPIUpdateData = this.$apiurl + "coa/updatecoa/" + idx;

        // console.log(urlAPIUpdateData);

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdateData, paramdata, { headers: headers })
          .then((resp) => {
            console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "coa" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
